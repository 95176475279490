import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useStores from '../../hooks/useStores';
import Product from './Product';
import ErrorBanner from '../../components/404';
import { InstantSearch } from 'react-instantsearch';
import { searchClient } from '../../services/Algolia';
import config from '../../services/Config';
import aa from 'search-insights';

const ProductIndex = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { slug } = useParams<{ slug: string }>();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { products } = useStores();

  const urlParams = new URLSearchParams(search);
  const queryId = urlParams.get('queryId');

  useEffect(() => {
    aa('init', {
      appId: config.algoliaAppId,
      apiKey: config.algoliaSearchKey,
    });
  }, []);

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const product = await products.loadBySlug(slug);
        setCurrentProduct(product);
        aa('viewedObjectIDs', {
          index: config.algoliaSearchIndex,
          eventName: 'Items Viewed',
          objectIDs: [`p${product.id}`],
        });
      } catch (error) {
        setIsLoading(false);

        // Failed to load product. Probably 404.
        throw error;
      }

      setIsLoading(false);
    };

    loadProduct();
  }, [slug]);

  if (isLoading) {
    return <div className="product loading" />;
  }

  if (!currentProduct) {
    return <ErrorBanner titleKeyword="product" />;
  }
  const handleAddToCart = (productId: number) => {
    try {
      aa('addedToCartObjectIDsAfterSearch', {
        eventName: 'Product Added To Cart',
        index: config.algoliaSearchIndex,
        queryID: queryId,
        objectIDs: [`p${productId}`],
        objectData: [
          {
            price: currentProduct.price,
            quantity: 1,
          },
        ],
        currency: 'EUR',
      });
    } catch (error) {
      console.error('Error tracking add to cart event:', error);
    }
  };
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={config.algoliaSearchIndex}
    >
      <Product
        slug={currentProduct.slug}
        navigate={navigate}
        product={currentProduct}
        productAddedToCart={handleAddToCart}
      />
    </InstantSearch>
  );
};

export default ProductIndex;
